import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// UBO
import Plaatjie from '@ubo/plaatjie'
import ButtonWhiteArrow from 'components/elements/Buttons/ButtonWhiteArrow'

interface CallToActionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CallToAction
}

const ImageWrapper = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 100px;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 120px;
    width: 120px;
  }

  @media screen and (max-width: 575px) {
    height: 100px;
    width: 100px;
  }

  @media screen and (max-width: 350px) {
    height: 80px;
    width: 80px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: 20px;
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  & p {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;

  & img {
    object-fit: cover !important;
  }
`

const CallToAction: React.FC<CallToActionProps> = ({ fields }) => (
  <section className="mt-5">
    <div className="row justify-content-center">
      <div className="col-lg-4 col-md-5 mb-3 mb-lg-0">
        {fields.image && (
          <ImageWrapper className="d-flex justify-content-center">
            <StyledPlaatjie image={fields.image} alt="Call to Action" />
          </ImageWrapper>
        )}
      </div>
      <div className="col-lg-5 col-md-7 d-flex align-items-center">
        <Content content={fields.description} />
      </div>
    </div>
  </section>
)

export default CallToAction
